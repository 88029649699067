global.sortIndexInit = function () {
  $('.js-sort select').on('change', (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('page');
    urlParams.set('sort', e.target.value);
    window.location.search = urlParams.toString();
  });
};

$(document).on('turbolinks:load', sortIndexInit);
