import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['slide'];

  initialize() {
    this.index = 0;
  }

  forward() {
    this.showSlide(this.index + 1);
  }

  back() {
    this.showSlide(this.index - 1);
  }

  showSlide(index) {
    this.index = index;
    this.slideTargets.forEach((el, i) => {
      if (index !== i) el.classList.add('hidden');
      if (index === i) el.classList.remove('hidden');
    });
  }
}
