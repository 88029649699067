import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notifications', 'panel', 'alertMobile', 'alertDesktop'];

  togglePanel() {
    const panel = this.panelTarget;
    $(panel).toggleClass('open');
    if ($(panel).hasClass('open')) this.loadNotifications();
    this.updateNavbar();
  }

  updateNavbar() {
    const alertMobile = this.alertMobileTarget;
    const alertDesktop = this.alertDesktopTarget;
    const unreadCount = $('#js-unread-count').data('unread') || 0;

    if (unreadCount === 0)
      $(alertMobile).add($(alertDesktop)).addClass('hidden');
    else $(alertMobile).add($(alertDesktop)).removeClass('hidden');
  }

  loadNotifications() {
    fetch('/notifications')
      .then((response) => response.text())
      .then((html) => {
        this.notificationsTarget.innerHTML = html;
      });
  }
}
