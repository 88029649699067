import Litepicker from 'litepicker';

global.litepickerInit = function () {
  $('.js-litepicker').each((_i, el) => {
    new Litepicker({ element: el });
  });
  $('.js-litepicker-range').each((_i, el) => {
    new Litepicker({ element: el, singleMode: false, numberOfMonths: 2 });
  });
};

$(document).on('turbolinks:load', litepickerInit);
