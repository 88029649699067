global.filterIndexInit = function () {
  $('#js-filter-panel').on('toggle', () => {
    $('.invertible[data-target="#js-filter-panel"]').toggleClass(
      'primary inverse'
    );
    $('#js-mobile-page-heading').toggleClass('hidden');
  });
};

$(document).on('turbolinks:load', filterIndexInit);
