global.toggleActionInit = function () {
  const close = (node) => {
    node.addClass('inverted');
    node.removeClass('primary relative z-50');
    node.find($('svg')).attr('data-icon', 'ellipsis-h');
  };

  const open = (node) => {
    node.addClass('primary relative z-50');
    node.removeClass('inverted');
    node.find($('svg')).attr('data-icon', 'times');
  };

  $('.js-toggle-action').click((event) => {
    const action = $(event.currentTarget);
    const isOpen = action.hasClass('primary');
    $('.js-toggle-action')
      .not(event.currentTarget)
      .each((i, el) => close($(el)));
    isOpen ? close(action) : open(action);
  });

  $(document).click((event) => {
    const isTrigger = !!event.target.closest('js-toggle-action');
    const isActionPanel = !!event.target.closest('.js-action-toggle-container');
    if (isTrigger || isActionPanel) return;

    $('.js-toggle-action').each((i, el) => {
      const target = $(el).data('target');
      $(`.js-toggle${target}`).addClass('hidden');
      close($(el));
    });
  });
};

$(document).on('turbolinks:load', toggleActionInit);
