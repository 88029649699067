global.toggleSessionInit = function () {
  $('.js-toggle-session-trigger').click((event) => {
    const target = $(event.currentTarget).data('variable');
    $.ajax({
      type: 'PUT',
      url: `/session_toggles/${target}`,
    });
    chosenInit();
  });
};

$(document).on('turbolinks:load', toggleSessionInit);
