import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';

const timePickerInit = () => {
  $('.timepicker').each((_i, el) => {
    flatpickr(el, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:i K",
      time_24hr: false,
    });
  });

};

$(document).on('turbolinks:load', timePickerInit);