import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'full_name',
      values: this.fetchEmployees,
      menuItemTemplate: this.displayEmployee
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchEmployees(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(employees => callback(employees))
      .catch(error => callback([]))
  }

  displayEmployee(item) {
    const { initials, employee: { headshot } } = item.original;

    let avatar;

    if (headshot) {
      avatar = `<img src="${headshot}" class="avatar sm object-cover" alt="${initials}" />`
    }
    else {
      avatar = `
          <div class="avatar bg-primary-dark flex-center sm">
            <div class="text-white">${initials}</div>
          </div>
      `;
    }

    return `
      <div class="mention inline-flex py-1">
        <div class="flex-initial px-2">
          ${avatar}
        </div>
        <div class="flex-initial pt-2">
          ${item.string}
        </div>
      </div>
    `;
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention['employee']['sgid'],
      content: mention['employee']['content']
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    let tributeLength = endPos - startPos
    let trixStartPos = position - tributeLength
    let trixEndPos = position

    this.editor.setSelectedRange([trixStartPos, trixEndPos])
    this.editor.deleteInDirection("backward")
  }
}
