global.chosenInit = function () {
  const options = {
    max_selected_options: 5,
    placeholder_text_multiple: 'Select',
    placeholder_text_single: 'Select',
  };
  $('.js-chosen-select:visible')
    .chosen(options)
    .on('change', (event, params) => {
      event.target.dispatchEvent(new CustomEvent('chosen:change'));
    });
  $('.js-chosen-select-single:visible')
    .chosen({ ...options, max_selected_options: 1 })
    .on('change', (event, params) => {
      event.target.dispatchEvent(new CustomEvent('chosen:change'));
    });
};

$(document).on('turbolinks:load', chosenInit);
$(document).on('turbolinks:before-cache', function () {
  $('.js-chosen-select').chosen('destroy');
  chosenInit();
});
