import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['author', 'feeds'];

  updateFeeds() {
    const feeds = this.feedsTarget;
    const endpoint = new URL(this.url);

    endpoint.searchParams.append('author_id', this.author);

    fetch(endpoint.href)
      .then((response) => response.text())
      .then((html) => {
        feeds.innerHTML = html;
      });
  }

  get author() {
    return this.authorTarget.value;
  }

  get url() {
    return this.data.get('url');
  }
}
