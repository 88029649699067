import Cropper from 'cropperjs';

// direct_uploads.js
global.directUploadsInit = function () {
  addEventListener('direct-upload:initialize', (event) => {
    const { target, detail } = event;
    const { id, file } = detail;

    if (!$(`#direct-upload-${id}`)[0]) {
      target.insertAdjacentHTML(
        'beforebegin',
        `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename">${file.name}</span>
        </div>
      `
      );
    }
  });

  addEventListener('direct-upload:start', (event) => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
  });

  addEventListener('direct-upload:progress', (event) => {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(
      `direct-upload-progress-${id}`
    );
    progressElement.style.width = `${progress}%`;
  });

  addEventListener('direct-upload:error', (event) => {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
  });

  addEventListener('direct-upload:end', (event) => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
  });

  const previewImage = function (imageFile, fileInput) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const placeholder = $('.js-image-preview-placeholder');
      const preview     = $('.image-preview');
      const image       = $('img.image-preview');

      placeholder.addClass('hidden');
      preview.removeClass('hidden');
      image.attr('src', e.target.result);

      if (image.hasClass('croppable')) {
        crop(image[0], { file: imageFile, input: fileInput });
      }
    };

    reader.readAsDataURL(imageFile);
  };

  function crop(image, { file, input }) {
    let cropper;

    const controls = $('.crop-zoom').removeClass('hidden');
    const enlarge  = controls.find('#zoomIn')[0];
    const shrink   = controls.find('#zoomOut')[0];

    function onChange() {
      cropper.getCroppedCanvas().toBlob(blob => {
        const transfer = new ClipboardEvent("").clipboardData || new DataTransfer();
        const cropped  = new File([blob], file.name, { type: blob.type });

        transfer.items.add(cropped);
        input.files = transfer.files;
      });
    }

    function onEnlarge() {
      cropper.zoom(0.1);
      shrink.removeAttribute('disabled');
    }

    function onShrink() {
      cropper.zoom(-0.1);
    }

    function onDestroy() {
      cropper.destroy();
      image.removeEventListener('cropend', onChange);
      image.removeEventListener('zoom', onChange);
      input.removeEventListener('change', onDestroy);
      enlarge.removeEventListener('click', onEnlarge);
      shrink.removeEventListener('click', onShrink);
      shrink.setAttribute('disabled', '');
    }

    input.addEventListener('change', onDestroy);
    image.addEventListener('cropend', onChange);
    image.addEventListener('zoom', onChange);
    enlarge.addEventListener('click', onEnlarge);
    shrink.addEventListener('click', onShrink);

    cropper = new Cropper(image, {
      aspectRation: 1,
      background: false,
      cropBoxResizable: false,
      dragMode: 'move',
      minCanvasWidth: 200,
      minCanvasHeight: 200,
      viewMode: 1,
    });
  }

  var onFileSelected = function (evt) {
    const fileInput = evt.target;
    const files = Array.from(evt.target.files).map((file) => file.name);
    const placeholder =
      fileInput.getAttribute('data-placeholder') || fileInput.placeholder;

    // Store original placeholder
    fileInput.setAttribute('data-placeholder', placeholder);

    // Update current placeholder
    if (files.length === 1) {
      fileInput.placeholder = files[0].replace(/.*[\/\\]/, '');
    } else if (files.length > 1) {
      fileInput.placeholder = `${files.length} files`;
    } else {
      fileInput.placeholder = placeholder;
    }

    if (evt.target.files && evt.target.files[0])
      previewImage(evt.target.files[0], fileInput);
  };

  $('input[type="file"]').each((index, el) =>
    $(el).on('change', onFileSelected)
  );

  $('video.protected').attr('controlsList', 'nodownload');
  $('video.protected, img.protected').bind('contextmenu', () => false);
};

$(document).on('turbolinks:load', directUploadsInit);
