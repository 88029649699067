global.appEvents = function () {
    var url = window.document.location.href;
    var queryParams;

    function getCSRFToken() {
        var metaElements = document.getElementsByTagName('meta');
        for (var i = 0; i < metaElements.length; i++) {
            if (metaElements[i].getAttribute('name') === 'csrf-token') {
                return metaElements[i].getAttribute('content');
            }
        }
        return '';
    }

    function duplicateEvent(eventId) {
        var csrfToken = getCSRFToken();
        var url = '/apprest/v1/events/' + eventId + '/duplicate';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
        .then(function(response) {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(function(error) {
                    throw new Error(error);
                });
            }
        })
        .then(function(data) {
            if (data.id) {
                window.location = '/admin/events/'+ data.id +'/edit';
            } else {
                alert('Could not complete this process.');
            }
        })
        .catch(function(error) {
            console.error('Error duplicating event:', error);
        });
    }

    // function handleFrequencyDatePicker() {
    //     if (eventFrequencyChosenSpan.innerHTML === 'Once') {
    //         eventRecurEndDate.style.visibility = 'hidden';
    //     } else {
    //         eventRecurEndDate.style.visibility = 'visible';
    //     }
    // }

    function getQueryParams(url) {
        var params = {};
        var urlObj = new URL(url);
        let queryParams = new URLSearchParams(urlObj.search);

        for (var [key, value] of queryParams.entries()) {
            params[key] = value;
        }

        return params;
    }

    queryParams = getQueryParams(url);

    // check for query params.
    if (queryParams.duplicate && queryParams.id) {
        var eventId = Number(queryParams.id);

        duplicateEvent(eventId);
    }
};

$(document).on('turbolinks:load', appEvents);
