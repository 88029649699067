global.searchSelectInit = function () {
  $('.search-select input[type=search]').on('search', (e) => {
    if (!e.target.value) {
      $('.search-select .dropdown').addClass('hidden');
    }
  });
};

global.searchIndexInit = function () {
  $('.search-index input[type=search]').on('search', (e) => {
    if (!e.target.value) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('q');
      window.location.search = urlParams.toString();
    }
  });
};

$(document).on('turbolinks:load', searchSelectInit);
$(document).on('turbolinks:load', searchIndexInit);
