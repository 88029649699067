import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['icon', 'body'];

  toggle() {
    const body = this.bodyTarget;
    $(body).toggleClass('hidden');

    const icon = this.iconTarget;
    $(icon).toggleClass('fa-chevron-down fa-chevron-up');
  }
}
