// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('intersection-observer');
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('trix');
require('@rails/actiontext');
require('chosen-js');
require('litepicker');
require('swiper');
require('cropperjs');

import '@fortawesome/fontawesome-pro/js/all';

import "lightgallery.js/dist/css/lightgallery.min.css";

import '../css/application';
import '../src/createNavigation';
import '../src/chosen';
import '../src/appEmployees';
import '../src/appEvents';
import '../src/directUpload';
import '../src/favorite';
import '../src/filter';
import '../src/litepicker';
import '../src/timepicker';
import '../src/modal';
import '../src/search';
import '../src/sort';
import '../src/swiper';
import '../src/tableFilter';
import '../src/toggleAction';
import '../src/toggleComponent';
import '../src/toggleSession';
import 'components';
import 'controllers';

require('lg-video.js/dist/lg-video.js');
