global.appEmployees = function () {
    var routeParams;

    function getCSRFToken() {
        var metaElements = document.getElementsByTagName('meta');
        for (var i = 0; i < metaElements.length; i++) {
            if (metaElements[i].getAttribute('name') === 'csrf-token') {
                return metaElements[i].getAttribute('content');
            }
        }
        return '';
    }

    function getPathParams() {
        var pathname = window.location.pathname;

        var parts = pathname.split('/');

        var filteredParts = parts.filter(part => part !== '');

        return {
            route: filteredParts[0],
            id: filteredParts[1]
        };
    }

    function getHeadshotRecord(headshotId, callback) {
        var csrfToken = getCSRFToken();
        var endpointUrl = '/apprest/v1/headshots_crud/' + headshotId;

        fetch(endpointUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
        .then(function(response) {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(function(error) {
                    throw new Error(error);
                });
            }
        })
        .then(function(data) {
            callback({
                success: true,
                message: 'Return the headshot record successfully!',
                data,
            });
        })
        .catch(function(error) {
            callback({
                success: false,
                message: 'There was an error returning your record.',
                error,
            });
        });
    }

    function getNotificationByUserId(employeeId, callback) {
        var csrfToken = getCSRFToken();
        var endpointUrl = '/apprest/v1/notifications_crud/' + employeeId;

        fetch(endpointUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
        .then(function(response) {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(function(error) {
                    throw new Error(error);
                });
            }
        })
        .then(function(data) {
            if (!data.error) {
                callback({
                    success: true,
                    message: 'Data is in.',
                    data,
                });
            } else {
                callback({
                    success: false,
                    message: 'There were no notifications found.'
                });
            }
        })
        .catch(function(error) {
            callback({
                success: false,
                message: 'There was an error returning your record.',
                error,
            });
        });
    }

    function createNotification(message, employeeId, callback) {
        var csrfToken = getCSRFToken();
        var endpointUrl = '/apprest/v1/notifications_crud';
        routeParams = getPathParams();

        const notificationData = {
            notification: {
                message,
                status: 'unread',
                notification_type: 'photo_image',
                user_id: employeeId
            }
        };

        fetch(endpointUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin',
            body: JSON.stringify(notificationData)
        })
        .then(function(response) {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(function(error) {
                    throw new Error(error);
                });
            }
        })
        .then(function(data) {
            callback({
                success: true,
                message: 'Message was submitted successfully!',
                data,
            });
        })
        .catch(function(error) {
            console.error('Error here:', error);
            callback({
                success: false,
                message: 'There was an error creating your message record.',
                error,
            });
        });
    }

    function removeHeadshotById(headshotId) {
        var csrfToken = getCSRFToken();
        var endpointUrl = '/apprest/v1/headshots_crud/' + headshotId;

        fetch(endpointUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
        .then(function(response) {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(function(error) {
                    throw new Error(error);
                });
            }
        })
        .then(function() {
            window.location.reload();
        })
        .catch(function(error) {
            alert('There was an error while removing this record.');
        });
    }

    function removeNotificationById(id) {
        var csrfToken = getCSRFToken();
        var endpointUrl = '/apprest/v1/notifications_crud/' + id;

        fetch(endpointUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
        .then(function(response) {
            if (response.ok) {
                window.location.reload();
            } else {
                return response.json().then(function(error) {
                    throw new Error(error);
                });
            }
        })
        .catch(function() {
            alert('There was an error while removing this record.');
        });
    }

    function closeAllModalBlocks() {
        var wrappers = document.querySelectorAll('.app-denial-outer-wrapper');
        wrappers.forEach(function(wrapper) {
            wrapper.style.display = 'none';
        });
    }

    function initRejectProfileImage() {
        var justDenyTriggers = document.querySelectorAll('.app-just-deny-reject');
        var denialTriggers = document.querySelectorAll('.app-denial-reject');

        justDenyTriggers.forEach(function(wrapper) {
            wrapper.addEventListener('click', function() {
                var photoId = wrapper.className.match(/app-denial-message-finalize-(\d+)/)[1];
                removeHeadshotById(photoId);
            });
        });

        // Find the reject button and locate it's id
        denialTriggers.forEach(function(wrapper) {
            wrapper.addEventListener('click', function() {
                var headshotId = wrapper.className.match(/app-denial-reject-dynamic-(\d+)/)[1];
                var denialMessageBlock = document.querySelector('.app-denial-outer-wrapper-dynamic-' + headshotId);
                var denialMessageBlockClose = document.querySelector('.app-denial-close-dynamic-' + headshotId);
                var rejectNowButton = document.querySelector('.app-denial-message-finalize-' + headshotId);

                if (denialMessageBlock.style.display === 'none') {
                    denialMessageBlock.style.display = '';
                } else {
                    denialMessageBlock.style.display = 'none';
                }

                // closes the modal
                denialMessageBlockClose.onclick = function() {
                    closeAllModalBlocks();
                };

                rejectNowButton.onclick = function() {
                    var messageTextArea = document.querySelector('.app-denial-message-textarea-' + headshotId);

                    if (messageTextArea.value.length > 0) {
                        getHeadshotRecord(headshotId, function(data) {
                            var employeeId = data && data.data.employee_id ? data.data.employee_id : false;

                            if (employeeId) {
                                createNotification(messageTextArea.value, employeeId, function() {
                                    removeHeadshotById(headshotId);
                                });
                            } else {
                                alert('Sorry, could not locate this headshot record.');
                            }
                        });
                    } else {
                        removeHeadshotById(headshotId);
                    }
                };
            });
        });
    }

    function handleProfileImageAlerts(id, message) {
        var alertBlock = document.querySelector('.app-profile-image-alert');
        var alertBlockPara = document.querySelector('.app-profile-image-alert p');
        var alertBlockCloseBtn = document.querySelector('.app-profile-image-alert-close');
        alertBlockPara.textContent = message;

        if (alertBlock.style.display === 'none') {
            alertBlock.style.display = '';
        }

        alertBlockCloseBtn.onclick = function() {
            removeNotificationById(id);
        }
    }

    if (window.location.pathname.includes('/employees')) {
        var routeParams = getPathParams();
        var employeeId = routeParams.id ? routeParams.id : false;

        if (employeeId) {
            getNotificationByUserId(employeeId, function(data) {
                if (data.success && data.data && data.data.message) {
                    handleProfileImageAlerts(data.data.id, data.data.message);
                }
            });
        }
    }

    if (window.location.pathname === '/admin/media') {
        initRejectProfileImage();
    }

};

$(document).on('turbolinks:load', appEmployees);
