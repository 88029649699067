import { Controller } from 'stimulus';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['fold'];

  connect() {
    if (typeof(IntersectionObserver) === undefined) {
      return;
    }

    this.observer = new IntersectionObserver(this.onObserved, { threshold: 1 });
    this.observer.observe(this.foldTarget);
  }

  disconnect() {
    if (this.observer === undefined) {
      return;
    }

    this.observer.unobserve(this.foldTarget);
    this.observer = null;
  }

  onObserved = (entries, observer) => {
    console.log('observing ...');

    for (let entry of entries) {
      if(entry.isIntersecting) {
        this.trackView();
        observer.unobserve(entry.target);
      }
    }
  }

  trackView = () => {
    console.log('tracking ...');

    fetch(this.data.get('url')).catch(error => console.log(error))
  }
}
