import Rails from '@rails/ujs';

const DURATION = 500;
const openModal = (target) => {
  const $bg = $(`.modal${target} .modal-bg-container`);
  const $body = $(`.modal${target} .modal-body`);

  $(`.modal${target}`).removeClass('hidden');

  $bg.animate({ opacity: 1 }, DURATION / 2);
  $body.animate({ opacity: 1 }, DURATION);
};

const closeModal = (target) => {
  const $modal = $(target);
  const $bg = $modal.find('.modal-bg-container');
  const $body = $modal.find('.modal-body');

  $('video').trigger('pause');

  $bg.animate({ opacity: 0 }, DURATION);
  $body.animate({ opacity: 0 }, DURATION / 2);

  setTimeout(() => {
    $modal.addClass('hidden');
  }, DURATION);
};

global.modalInit = function () {
  $('.modal-trigger').click((event) => {
    const target = $(event.currentTarget).data('target');
    openModal(target);
  });
  $('.modal .modal-close').click((event) => {
    const $modal = $(event.currentTarget).closest('.modal');
    closeModal(`#${$modal.attr('id')}`);
  });
};

global.modalFormsInit = function () {
  const hideModal = function () {
    closeModal(`#${$('.js-modal-form').attr('id')}`);
  };

  $('.js-modal-form').on('submit', hideModal);
  return { hideModal };
};

$(document).on('turbolinks:load', modalInit);
// $(document).on('turbolinks:load', modalFormsInit);

const showConfirmationDialog = (element) => {
  const title = element.getAttribute('data-confirmTitle');
  const content = element.getAttribute('data-confirm');

  if (title) $('#confirmationModal .modal-title').text(title);
  if (content) $('#confirmationModal .modal-body p').text(content);

  openModal('#confirmationModal');
  $('#confirmationModal').on('click', '.btn.primary', () => {
    closeModal('#confirmationModal');
    element.removeAttribute('data-confirm');
    element.click();
  });
};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm') === null) {
    return true;
  }
  showConfirmationDialog(element);

  return false;
};

function handleAlertConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

// Rails.delegate(document, 'a[data-confirm]', 'click', handleAlertConfirm);
Rails.delegate(document, 'button[data-confirm]', 'click', handleAlertConfirm);
Rails.delegate(document, 'input[data-confirm]', 'click', handleAlertConfirm);
