global.createNavigationInit = function () {
  $('.js-create-navigation').click((event) => {
    const target = event.currentTarget;
    const nav = $(target).data('nav') || 'admin_nav_open';
    $.ajax({
      type: 'POST',
      url: '/navigations',
      data: { nav },
      success: function (response) {
        $(".js-sub-nav[data-target='" + nav + "']").toggleClass('hidden');
      },
    });
  });
};

$(document).on('turbolinks:load', createNavigationInit);
