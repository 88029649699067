import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['player'];

  setPlayer(event) {
    const url = event.currentTarget.getAttribute('data-src');
    const orientation = event.currentTarget.getAttribute('data-orientation');
    const player = this.playerTarget;
    $('#video-player-modal .modal-body').css(
      'max-width',
      orientation === 'portrait' ? '28rem' : '48rem'
    );
    player.setAttribute('src', url);
    player.play();
  }
}
