global.favoriteInit = function () {
  const addFavorite = (appId) => {
    $.ajax({
      type: 'POST',
      url: `/apps/${appId}/usage`,
      data: { appId },
      success: function (response) {
        const favorite = $("#favorite-star[data-app-id='" + response.appId + "']")

        favorite.removeClass('not-favorited')
        favorite.addClass('favorited')
      }
    })
  };

  const removeFavorite = (appId) => {
    $.ajax({
      type: 'DELETE',
      url: `/apps/${appId}/usage`,
      data: { appId },
      success: function (response) {
        const favorite = $("#favorite-star[data-app-id='" + response.appId + "']")

        favorite.removeClass('favorited')
        favorite.addClass('not-favorited')
      }
    })
  };

  $('.favorite-icon').click((event) => {
    const target = event.currentTarget
    const appId = $(target).find('#favorite-star').data('app-id')
    const isFavorite = $(target).find('#favorite-star').hasClass('favorited')

    isFavorite ? removeFavorite(appId) : addFavorite(appId);
  });
}

$(document).on('turbolinks:load', favoriteInit);
